import {
  Component, OnInit, OnDestroy,
  ElementRef,
  QueryList,
  Inject,
  Input,
  Optional,
  Self,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet, Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgxEditorModule } from 'ngx-editor';
import { Validators, Editor, Toolbar, toDoc, toHTML } from 'ngx-editor';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  // Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ENUMS } from '../../models/constants';
import { WorkOrderFilesViewerComponent } from '../work-order-files-viewer/work-order-files-viewer.component';
import { environment } from '../../env/environment';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { AuthService } from '../services/auth.service';
import { AppServiceService } from '../services/app-service.service';
import { interval, Subject, Subscription, Observable, forkJoin, lastValueFrom } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { filter } from 'rxjs/operators';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [
    CommonModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FontAwesomeModule,
    ContactUsComponent,
    FooterComponent,
    RouterModule,
  ],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;

  editor: Editor;
  editorContent: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike', 'format_clear'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  title: any;
  subTitle: any;
  reached_max_char_title = false;
  reached_max_char_content = false;
  max_char_title = ENUMS.USER_INPUT_LIMIT_LONG;
  max_char_content = ENUMS.USER_INPUT_LIMIT_LONG;

  environment = environment;
  url: any;
  session: any;
  showBlogSection = false;

  file: any = [];
  fileUpdate: any = [];
  fileUpdateCurrentIndex: number = 0;
  message: string = '';
  fileUpdateMessage: any = [];
  canUpload = false;
  totalSize = 0;
  totalAllowedFileSizePerWO = 8.3e6;
  filePaths: any = [];

  blogId = null;
  blogContentFileName = null;

  urlBlogsFileUpload = `${environment.rootUrl}prod/blogs/upload`;
  urlBlogContentFileDownload;

  progressBarDialogRef: any;
  bannerImage: string;
  activeBlog: boolean = false;
  category;
  categories = [
    'Buyers',
    'Sellers',
    'Home Care',
  ]

  editingBlog = false;

  blogList = [];
  startAutoSave = false;

  initialJSON = {
    "type": "doc",
    "content": [
      {
        "type": "paragraph",
        "attrs": {
          "align": null,
          "indent": null
        }
      },
    ]
  };

  viewBlogs = false;
  loadedBlog = false;

  intervalCheckTokenExpSubscription$;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  constructor(
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    public authService: AuthService,
    public dialog: MatDialog,
    public appService: AppServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2, private el: ElementRef,
    private ngZone: NgZone,
    private location: Location,
  ) {
    this.url = `${environment.rootUrl}prod/blogs/`;

    this.appService.checkMobile();
  }

  async ngOnInit() {
    this.session = await this.authService.getCurrentSession();

    this.editor = new Editor();

    this.editorContent = toHTML(this.initialJSON);

    setTimeout(() => {
      this.showBlogSection = true;
    }, 500)

    // Turn on Auto Save for prod deploy
    // TODO: fix auto save
    this.autoSave();

    this.getBlogs();

    this.urlBlogContentFileDownload = `https://${this.getDomainName().toLowerCase()}.s3.us-east-1.amazonaws.com/`;

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: any) => {
    //   this.viewBlogs = event.url.includes('blogs');
    // })

    this.viewBlogs = this.router.url.includes('blogs');

    // On load of blogs after from blogs-detail, need to reset URL to blogs
    if (this.viewBlogs && !this.title) {
      this.location.go('/blogs')
    }
  }

  showLocalTime(utc) {
    return (new Date(utc)).toLocaleString()
  }

  showLocalDate(utc) {
    let utcDate = utc.replaceAll('__', '.').replaceAll('_', ':')
    if (utcDate.includes('.json')) {
      utcDate = utcDate.split('.json')[0];
    }
    return (new Date(utcDate)).toLocaleDateString()
  }

  getBlogs() {
    let data = {
      blogDomain: this.getDomainName().toLowerCase()// + 'blogs',
    }
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)

    this.httpClient.post(this.url, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log(JSON.stringify(data))
        if (data.body) {
          this.blogList = data.body;

          // Can get to individual blog with blogId e.g. blogs-detail?blogId=test1--2024-11-17T12_35_05__930Z
          this.route.queryParams.subscribe(params => {
            if (params['blogId']) {
              this.blogId = params['blogId'];

              let blogFromQueryParam = this.blogList.find(b => b.blogId === this.blogId);
              this.editBlog(blogFromQueryParam)
            }
          })
        }
      }
    )
  }

  // Blog mobile strategy 
  // 1. View: mobile get _mobile json
  // 2. Edit: mobile/desktop both get desktop json
  // 3. Edit - Lambda save: replace _mobile img width with 300px

  editBlog(blog: any) {
    this.editingBlog = true;

    this.startAutoSave = true;
    this.autoSave() // NOT auto saving

    this.filePaths = blog.filePaths;

    this.category = blog.category;

    this.filePaths.forEach(f => {
      f.uploadUrl = f.publicUrl;  // For view modal 
    })

    let blogContentUrl = null;
    let blogContentFileNameTemp = null;
    if (blog.blogContentFileName) {
      this.blogContentFileName = blog.blogContentFileName;

      if ((this.appService.isMobile && this.viewBlogs)
        && !blog.blogContentFileName.includes('mobile')) {
        let splitContentFileName = blog.blogContentFileName.split('.');
        blogContentFileNameTemp = splitContentFileName[0] + '_mobile.' + splitContentFileName[1];
      } else {
        blogContentFileNameTemp = blog.blogContentFileName;
      }

      blogContentUrl = `${this.urlBlogContentFileDownload}${blog.blogId}/${blogContentFileNameTemp}`;
      this.httpClient.get(blogContentUrl, { observe: 'response' }).subscribe(
        (data: any) => {
          console.log(data.body)

          this.editorContent = toHTML(data.body);
          // if (data.url) {
          //   this.editorContent = data.body;
          // } else {
          //   this.editorContent = toHTML(data.body);
          // }
        },
        (e) => {
          console.log(JSON.stringify(e))
        }
      )
    }

    // if (blog.bannerImage) {
    //   // blogContentUrl = `${blog.bannerImage}`;
    //   this.httpClient.get(blog.bannerImage, { headers: this.httpHeaders, observe: 'response' }).subscribe(
    //     (data: any) => {
    //       this.bannerImage = blog.bannerImage;
    //     }
    //   )
    // }
    if (blog.bannerImage) {
      this.bannerImage = blog.bannerImage;
    }

    this.title = blog.title;
    this.subTitle = blog.subTitle;
    this.activeBlog = blog.active;
    this.category = blog.category;
    this.blogId = blog.blogId;


    setTimeout(() => {
      if (this.viewBlogs) {
        this.location.go('/blogs-detail')
      }
    }, 1000)

    // need to reload page after submit else current list of blogs on top is stale
  }

  userInput(type: string) {
    switch (type) {
      case 'title':
      case 'subTitle':
        if (this.title.length >= this.max_char_title) {
          this.reached_max_char_title = true;
        } else {
          this.reached_max_char_title = false;
        }
        break;
      case 'content':
        if (this.editorContent && this.editorContent.length >= this.max_char_content) {
          this.reached_max_char_content = true;
        } else {
          this.reached_max_char_content = false;
        }
        break;
    }
  }


  getHtmlContent(): SafeHtml {
    if (this.editorContent) {
      if (this.editorContent.type) {
        return this.sanitizer.bypassSecurityTrustHtml(toHTML(this.editorContent) || '')
      } else {
        this.loadedBlog = true;

        setTimeout(() => {
          this.cdr.detach()
        }, 1000);

        // return this.sanitizer.bypassSecurityTrustHtml(toHTML(this.editorContent) || '')
        let content = this.sanitizer.bypassSecurityTrustHtml(this.editorContent || '')
        return content;
      }
    }
    return '';
  }

  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input?.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonContent = JSON.parse(e.target?.result as string);
          this.editorContent = toHTML(jsonContent);  // Convert JSON to HTML and set it in the editor
        } catch (error) {
          console.error('Invalid JSON file:', error);
        }
      };
      reader.readAsText(file);
    }
  }

  exportContent(): void {
    try {
      const jsonDoc = toDoc(this.editorContent);
      const blob = new Blob([JSON.stringify(jsonDoc, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'editor-content.json';
      a.click();

      window.URL.revokeObjectURL(url);  // Clean up
    } catch (error) {
      console.error('Error converting content to JSON:', error);
    }
  }

  getDomainName() {
    // return this.environment.hostname.split("www.")[1].split(".com")[0];
    return this.environment.domainName;
  }

  // TODO: first save always object object, then edit save woudl be fine?
  saveBlog(manualSave = true) {
    // let jsonDoc = toDoc(this.editorContent);
    const jsonDoc = toDoc(this.editorContent);

    console.log('jsonDoc:' + JSON.stringify(jsonDoc))

    let data = {
      blogId: this.getBlogId(),
      blogDomain: this.getDomainName().toLowerCase(),// + 'blogs',

      // Test
      // blogContentFileName: '2024-11-01T23:44:34.896Z.ts',

      blogContentFileName: this.getBlogContentFileName(true),
      blogJson: jsonDoc,

      // DB fields
      updateDB: true,
      blogDBFields: {
        // blogDomain: this.getDomainName() + 'blogs',
        title: this.title,
        subTitle: this.subTitle,
        filePaths: this.filePaths,
        category: this.category,
        active: this.activeBlog,
        // bannerImage: this.filePaths && this.filePaths.length > 0 ? this.filePaths[0].path.split('/')[1] : '',
        bannerImage: this.bannerImage,
      },
    }

    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.put(this.url, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log(data)

        // test - why even fileContent is updated in S3 file, but api gets old version
        // let blogContentUrl = `${this.urlBlogContentFileDownload}${this.blogId}/${this.blogContentFileName}`;
        // this.httpClient.get(blogContentUrl, { observe: 'response' }).subscribe(
        //   (data: any) => {
        //     console.log(data.body)
        //   }
        // )

        if (manualSave) {
          this.startAutoSave = false;
          if (this.intervalCheckTokenExpSubscription$) {
            this.intervalCheckTokenExpSubscription$.unsubscribe();
          }
          this.blogId = null;
          this.blogContentFileName = null;
          this.title = null;
          this.subTitle = null;
          this.filePaths = [];
          this.category = null;
          this.activeBlog = false;
          this.bannerImage = null;
          this.editorContent = null;
          this.file = null;
          this.fileInput.nativeElement.value = '';
        }

        // Reload if not in Edit mode with auto save
        if (manualSave) {
          setTimeout(() => {
            this.editingBlog = false;
            // this.getBlogs();
            location.reload();  // TODO. page need to reload otherwise editBlog's this.httpClient.get executes but no API call to get content
          }, 1000)
        }
      }
    )
  }

  cancelBlog() {
    this.ngZone.run(() => {
      this.cdr.reattach()
      this.cdr.markForCheck();
      this.cdr.detectChanges();

      setTimeout(() => {
        this.title = null;
      }, 800)
    })

    this.cdr.reattach()
    this.cdr.markForCheck();
    this.cdr.detectChanges();

    setTimeout(() => {
      this.title = null;
    }, 500)

    this.startAutoSave = false;
    if (this.intervalCheckTokenExpSubscription$) {
      this.intervalCheckTokenExpSubscription$.unsubscribe();
    }
    this.editingBlog = false;

    this.blogId = null;
    this.blogContentFileName = null;
    this.title = null;
    this.subTitle = null;
    this.filePaths = [];
    this.category = null;
    this.activeBlog = false;
    this.bannerImage = null;
    this.editorContent = null;
    this.file = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }

    if (this.viewBlogs) {
      this.location.go('/blogs-detail')
    }
  }

  getS3FilePaths(files: any, address: any = null) {
    // let updatedNames: string[] = [];
    let updatedFileName: string[] = [];

    for (const file of files) {
      let fileName = file.name;
      let fileExt = '';

      let splitFileName = fileName.split('.');
      if (splitFileName.length <= 2) {
        fileName = splitFileName[0].split(' ').join('_');
      } else {
        fileName = splitFileName.slice(0, splitFileName.length - 2).join('_');
      }
      fileExt = splitFileName[splitFileName.length - 1];

      if (address) {
        updatedFileName.push(address + '/' + fileName + `.${fileExt}`);
      } else {
        updatedFileName.push(fileName + `.${fileExt}`);
      }
    }

    return updatedFileName;
  }

  onFileSelected(event: any) {
    this.message = '';
    const file: File[] = event.target.files;

    this.file = [];
    if (file) {
      this.file = Array.from(file);
    }

    if (this.file && this.file.length > 0) {
      this.totalSize = this.file.reduce((partial: any, f: any) => partial + f.size, 0)

      if (this.totalSize > this.totalAllowedFileSizePerWO) {
        this.file = null;
        this.fileInput.nativeElement.value = '';
        this.message = "Total size exceeded 9M. Please upload smaller size."
      }
    }
  }

  // Step 1: Get Pre-Signed URL from Lambda
  getUploadUrl(data: any): Observable<any> {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.put(this.urlBlogsFileUpload, data, { headers: this.httpHeaders, observe: 'response' });
  }

  // Step 2: Upload file to S3 using the pre-signed URL
  uploadFileToS3(updatedFileName: any, file: File, signedUrl: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': file.type  // Ensure the content type matches the file type
    });

    return this.httpClient.put(signedUrl, file, {
      headers,
    });
  }

  formatTitle(title) {
    if (title) {
      return title.trim().split(' ').join('_');
    }
    return '';
  }

  getBlogId() {
    if (!this.blogId) {
      this.blogId = this.formatTitle(this.title) + '--' + this.getBlogContentFileName();
    }

    // test
    // this.blogId = 'test-title--2024-11-02T14:31:23.655Z';

    return this.blogId;
  }

  getBlogContentFileName(withJSONext = false) {
    if (!this.blogContentFileName) {
      if (withJSONext) {
        this.blogContentFileName = (new Date()).toISOString().replaceAll(':', '_').replaceAll('.', '__') + '.json';
      } else {
        this.blogContentFileName = (new Date()).toISOString().replaceAll(':', '_').replaceAll('.', '__');
      }
    } else {
      if (withJSONext && !this.blogContentFileName.includes('.json')) {
        this.blogContentFileName += '.json';
      }
    }
    return this.blogContentFileName;
  }

  async uploadFileWithSignedUrl() {
    this.openProgressBar();

    if (this.file && this.file.length > 0) {
      let allFileSignedUrls: Observable<any>[] = [];
      let updatedFileNames: string[] = [];

      updatedFileNames = this.getS3FilePaths(this.file);

      let updatedFileNamesIndex = 0;
      for (const file of this.file) {
        try {

          const data = {
            fileName: updatedFileNames[updatedFileNamesIndex],
            contentType: file.type,
            blogId: this.getBlogId(),
            signedUrl: true,
          };

          let signedUrlPromise = this.getUploadUrl(data);

          if (signedUrlPromise) {
            allFileSignedUrls.push(signedUrlPromise);
          }

          let key = this.getBlogId() + '/' + updatedFileNames[updatedFileNamesIndex];
          let encodedKey = encodeURI(key);
          // Save file paths for later download
          this.filePaths.push({
            path: key,
            type: this.file[updatedFileNamesIndex].type,
            size: this.file[updatedFileNamesIndex].size,
            // publicUrl: `https://${this.getDomainName() + 'blogs'}.s3.amazonaws.com/${encodedKey}`
            publicUrl: `https://${this.getDomainName().toLowerCase()}.s3.amazonaws.com/${encodedKey}`
          })

          ++updatedFileNamesIndex;
        }
        catch (e) {
          console.log(e);
        }
      }

      this.updateProgress(35)

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {

          this.updateProgress(65)

          console.log('Got all file signed URL succeeded:', responses);

          // Just 1 file url
          // const uploadUrl = responses[0].body.uploadUrl;
          // this.uploadFileToS3(this.file[0], uploadUrl).subscribe(res => {
          //   console.log(" !!!!! Completed upload using signed URL !!!!!!")
          // })

          let allFileUploads: Observable<any>[] = [];

          responses.forEach((res: any, index: number) => {
            const uploadUrl = res.body.uploadUrl;
            let fileUploadPromise = this.uploadFileToS3(updatedFileNames[index], this.file[index], uploadUrl)

            if (fileUploadPromise) {
              allFileUploads.push(fileUploadPromise);
            }
          })

          forkJoin(allFileUploads).subscribe({
            next: (responses) => {

              this.updateProgress(75)
              this.closeProgressBar();

              this.file = [];
              this.fileInput.nativeElement.value = '';

              console.log('All file upload calls succeeded:', responses);
            },
            error: (error) => {
              this.closeProgressBar();
              console.error('Error in file upload calls:', error);
            }
          })
        },
        error: (error) => {
          this.closeProgressBar();
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }

  getDownloadUrl(data: any): Observable<any> {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.post(this.urlBlogsFileUpload, data, { headers: this.httpHeaders, observe: 'response' });
  }

  viewFileUpload() {
    let allFileSignedUrls: Observable<any>[] = [];

    let filePaths = this.filePaths;

    if (filePaths) {
      filePaths.forEach((filePath: any) => {
        const data = {
          fileName: filePath.path,
          // contentType: file.type,  // don't need for get(post)
          // address: this.repairItem.address, // lambda if no address, use fileName as full path to s3
          signedUrl: true,
        };

        let signedUrlPromise = this.getDownloadUrl(data);

        if (signedUrlPromise) {
          allFileSignedUrls.push(signedUrlPromise);
        }
      })

      // this.filePathsSignedURLPerWO = [];

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {
          responses.forEach((signedUrl: any, index: number) => {
            this.filePaths[index] = {
              ...this.filePaths[index],
              ...signedUrl['body']
            };
            console.log(`signedUrl['body']: JSON.stringify(${signedUrl['body']})`)
          })

          const dialogRef = this.dialog.open(WorkOrderFilesViewerComponent, {
            width: this.appService.isMobile ? '100%' : '55%',
            data: {
              filePathsSignedURLPerWO: this.filePaths,
              filePaths: filePaths,
            },
            panelClass: 'view-wo-files-dialog-container',
          });
        },
        error: (error) => {
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }

  openProgressBar(): void {
    this.progressBarDialogRef = this.dialog.open(ProgressBarComponent, {
      width: '100%',
      panelClass: 'progressbar-dialog-container',
      data: { progress: 0 },
      disableClose: true,
      hasBackdrop: true
    });
  }

  updateProgress(progressValue: number): void {
    if (this.progressBarDialogRef && this.progressBarDialogRef.componentInstance && 'progress' in this.progressBarDialogRef.componentInstance) {
      this.progressBarDialogRef.componentInstance.progress = progressValue;
    }
  }

  closeProgressBar(): void {
    if (this.progressBarDialogRef) {
      this.progressBarDialogRef.close();
    }
  }

  getFileName(path) {
    if (path && path.path) {
      return path.path.split('/')[1];
    }
    return null;
  }

  autoSave() {
    this.intervalCheckTokenExpSubscription$ = interval(1000 * 60)
      .subscribe(() => {
        if (this.startAutoSave) {
          console.log('auto save ' + new Date());
          this.saveBlog(false)
        }
      })
  }

  disableSubmit() {
    if (!this.title
      || !this.category
      || !this.editorContent
      || this.editorContent === toHTML(this.initialJSON)
    ) {
      return true;
    }
    return false;
  }

  disableViewPhotos() {
    if (!this.filePaths || (this.filePaths && this.filePaths.length === 0)) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }

    if (this.intervalCheckTokenExpSubscription$) {
      this.intervalCheckTokenExpSubscription$.unsubscribe();
    }
  }
}
