<div *ngIf="viewBlogs && !title" class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="blog-list-wrapper mt-5">
            <div class="title relative">
                FOR YOU
            </div>

            <div *ngFor="let blog of blogList">
                <div *ngIf="blog.active" (click)="editBlog(blog)" class="blog-item-wrapper">
                    <div class="d-flex justify-content-between"
                        [class]="appService.isMobile ? 'flex-column flex-reverse' : ''">
                        <div class="d-flex flex-column">
                            <div>Category: {{ blog.category }}</div>
                            <div class="blog-title">{{ blog.title }}</div>
                            <div class="blog-sub-title">{{ blog.subTitle }}</div>
                            <div *ngIf="!appService.isMobile" class="blog-date mt-1">{{
                                showLocalDate(blog.blogContentFileName) }}</div>
                        </div>
                        <div class="mt-1">
                            <img style="margin-bottom: 15px" src="{{blog.bannerImage}}" width="250vw">
                        </div>
                        <div *ngIf="appService.isMobile" class="blog-date mt-1">{{
                            showLocalDate(blog.blogContentFileName) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>

    <app-footer class="footer"></app-footer>
</div>

<div *ngIf="viewBlogs && title" class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="blog-list-wrapper mt-5">
            <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                    <!-- <a style="cursor: pointer; color: blue" href="/blogs-back-to-list"> -->
                    <!-- <a style="cursor: pointer; color: blue" href="/blogs-back-to-list" (click)="cancelBlog()"> -->
                    <li class="breadcrumb-item">
                        <a style="cursor: pointer; color: blue" href='/blogs-detail'>
                            Blogs
                        </a>
                    </li>
                    <!-- </a> -->
                    <!-- </a> -->
                    <li class="breadcrumb-item active" aria-current="page">{{ title }} </li>
                </ol>
            </nav>

            <div class="mt-2">
                <div class="d-flex justify-content-between"
                    [class]="appService.isMobile ? 'flex-column flex-reverse' : ''">
                    <div class="d-flex flex-column">
                        <div>Category: {{ category }}</div>
                        <div class="blog-title">{{ title }}</div>
                        <div class="blog-sub-title">{{ subTitle }}</div>
                        <div *ngIf="!appService.isMobile" class="blog-date mt-1">{{ showLocalDate(blogContentFileName)
                            }}</div>
                    </div>
                    <div class="mt-1">
                        <img style="margin-bottom: 15px" src="{{bannerImage}}" width="250vw">
                    </div>
                    <div *ngIf="appService.isMobile" class="blog-date mt-1">{{
                        showLocalDate(blogContentFileName) }}</div>
                </div>
            </div>
            <div class="html-container mt-3" style="font-size: 1.2rem" [innerHTML]="getHtmlContent()">
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>

    <app-footer class="footer"></app-footer>
</div>

<div *ngIf="!editingBlog && !viewBlogs" class="d-flex flex-column align-items-center mb-2" style="width: 60vw">
    <button mat-flat-button (click)="editingBlog = true"
        class="d-flex mb-3 justify-content-center button-default text-uppercase cursor-pointer" [disabled]="false">New
        Blog</button>
    <div *ngFor="let blog of blogList">
        <div (click)="editBlog(blog)" class="d-flex justify-content-between blog-edit-list-wrapper">
            <div class="">{{blog.title}}</div>
            <!-- <div>{{blog?.filePaths?.length}}</div> -->
            <div>{{ showLocalDate(blog.blogContentFileName) }}</div>
        </div>
    </div>
</div>

<hr *ngIf="!viewBlogs">

<div *ngIf="editingBlog && !viewBlogs">
    <div class="d-flex mt-2 mb-3">
        <mat-form-field class="" appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="title" (input)="userInput('title')" maxLength="{{ max_char_title }}" required>
            <mat-hint>Title</mat-hint>
        </mat-form-field>
    </div>

    <mat-form-field class="" appearance="fill">
        <mat-label>SubTitle</mat-label>
        <input matInput [(ngModel)]="subTitle" (input)="userInput('subTitle')" maxLength="{{ max_char_title }}">
        <mat-hint>Sub Title</mat-hint>
    </mat-form-field>

    <div class="d-flex justify-content-between">
        <div class="d-flex flex-column wide-section-inner align-items-start">
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <input type="file" #fileInput (change)="onFileSelected($event)" multiple required
                        [disabled]="!title" />
                    <div style="color: red">{{message}}</div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-center mr-3">
                        <button mat-flat-button (click)="uploadFileWithSignedUrl()"
                            class="d-flex justify-content-center button-default text-uppercase"
                            [disabled]="!file || file.length === 0">Upload</button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button mat-flat-button (click)="viewFileUpload()"
                            class="d-flex justify-content-center button-default text-uppercase"
                            [disabled]="disableViewPhotos()">View</button>
                    </div>
                </div>
            </div>

            <div class="d-flex mt-2">
                <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                    <mat-label>Category</mat-label>
                    <mat-select [(ngModel)]="category" required>
                        <mat-option *ngFor="let cat of categories" [value]="cat">{{ cat }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                    <mat-label>Banner Image</mat-label>
                    <mat-select [(ngModel)]="bannerImage">
                        <mat-option *ngFor="let path of filePaths" [value]="path.publicUrl">{{ getFileName(path)
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <img class="ml-1" style="margin-bottom: 15px" src="{{bannerImage}}" width="80vw">
            </div>

        </div>

        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="statusOpen" checked [(ngModel)]="activeBlog">
            <label class="form-check-label" for="statusOpen">Activte</label>
        </div>
    </div>

    <div class="d-flex">
        <!-- <mat-form-field class="mt-2" appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="title" (input)="userInput('title')" maxLength="{{ max_char_title }}" required>
            <mat-hint>Title</mat-hint>
        </mat-form-field> -->

        <!-- <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
            <mat-label>Banner Image</mat-label>
            <mat-select [(ngModel)]="bannerImage">
                <mat-option *ngFor="let path of filePaths" [value]="path.publicUrl">{{ getFileName(path) }}</mat-option>
            </mat-select>
        </mat-form-field> -->

        <!-- {{bannerImage}} -->
    </div>

    <div *ngIf="showBlogSection" class="editor-wrapper mt-3">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
        <ngx-editor [editor]="editor" [(ngModel)]="editorContent" [disabled]="false" [spellcheck]="true"
            [placeholder]="'Content here...'" (input)="userInput('content')" [style.min-height]="'500px'"
            [style.height]="'auto'"></ngx-editor>

        <!-- Keep here: export and import for debugging purpose -->
        <!-- <button (click)="exportContent()">Export JSON</button>
        <input type="file" (change)="onFileSelect($event)" /> -->
    </div>

    <div class="d-flex justify-content-center mt-2">
        <button mat-flat-button (click)="cancelBlog()"
            class="d-flex justify-content-center button-default text-uppercase mr-3" [disabled]="false">Cancel</button>
        <button mat-flat-button (click)="saveBlog()" class="d-flex justify-content-center button-default text-uppercase"
            [disabled]="disableSubmit()">Submit</button>
    </div>

    <!-- Show HTML content -->
    <!-- <img src="{{bannerImage}}" width="80vw"> -->
    <!-- <div class="html-container" [innerHTML]="getHtmlContent()"> -->
    <!-- </div> -->
</div>